import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")
  const _component_ion_header = _resolveComponent("ion-header")
  const _component_jn_area_tematica_card = _resolveComponent("jn-area-tematica-card")
  const _component_ion_col = _resolveComponent("ion-col")
  const _component_ion_row = _resolveComponent("ion-row")
  const _component_ion_grid = _resolveComponent("ion-grid")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { mode: "md" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        fullscreen: "",
        mode: "md"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.testate, (t) => {
                    return (_openBlock(), _createBlock(_component_ion_col, {
                      key: t,
                      size: "12",
                      "size-md": "6",
                      "size-lg": "4",
                      class: "ion-no-padding"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_jn_area_tematica_card, { testata: t }, null, 8, ["testata"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}